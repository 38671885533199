.editarea {
    width: 220px;
}

.profile {
    white-space: pre-wrap;
    font-size: 16px;
    height: calc( 1.3em * 5 );
    line-height: 1.3;
}
